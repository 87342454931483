<template>
  <div class="main">
    <div class="content">
      <div class="header">
        <el-button @click="handlePrint" icon="el-icon-printer" type="primary">打印</el-button>
      </div>
      <table>
        <thead>
          <th>申请人</th>
          <th>司机</th>
          <th>车辆</th>
          <th>派车时间</th>
          <th>申请时间</th>
          <th>目的地</th>
          <th>预计返回时间</th>
          <th>备注</th>
          <th>处理人</th>
          <th>审批人</th>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.id">
            <td width="70">{{ item.applicant }}</td>
            <td width="70">{{ item.driver ? item.driver.name : '' }}</td>
            <td width="120">{{ item.car ? item.car.name : '' }}</td>
            <td width="80">
              <div v-if="item.dispatchTime !== undefined" class="text-center">
                <p>{{ item.dispatchTime[0] }}</p>
                <p>{{ item.dispatchTime[1] }}</p>
              </div>
            </td>
            <td width="80">
              <div v-if="item.applyTime !== undefined" class="text-center">
                <p>{{ item.applyTime[0] }}</p>
                <p>{{ item.applyTime[1] }}</p>
              </div>
            </td>
            <td width="150">{{ item.target }}</td>
            <td width="90">
              <div v-if="item.returnTime !== undefined" class="text-center">
                <p>{{ item.returnTime[0] }}</p>
                <p>{{ item.returnTime[1] }}</p>
              </div>
            </td>
            <td width="150">{{ item.remark }}</td>
            <td>{{ item.user ? item.user.name : '' }}</td>
            <td>
              <el-image v-if="item.signature" style="width: 100px; height: 72px" :src="item.signature"
                :preview-src-list="[item.signature]">
              </el-image>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    getVehicleServiceDataAPI,
  } from '@/api/dispatch-vehicle.js'

  export default {
    data() {
      return {
        auditStatusDict: {
          0: {
            name: '已取消',
            type: 'info',
            value: 0
          },
          1: {
            name: '待安排',
            type: 'warning',
            value: 1
          },
          2: {
            name: '已安排',
            type: 'primary',
            value: 2
          },
          3: {
            name: '审批通过',
            type: 'success',
            value: 3
          },
          4: {
            name: '审批驳回',
            type: 'danger',
            value: 4
          },
          5: {
            name: '已出营',
            type: 'primary',
            value: 5
          },
          6: {
            name: '已返回',
            type: 'success',
            value: 6
          }
        },
        query: {
          page: 1,
          start: '',
          end: '',
          print: 1,
        },
        tableData: []
      }
    },
    /**
     * 来自dispach-vehicle/service.vue的数据：
     * @param {String} $route.query.dateRange 日期范围, 数据格式：yyyy-mm-dd/yy-mm-dd
     **/
    created() {
      let dateRange = this.$route.query.dateRange.split('/')
      this.query.start = dateRange[0]
      this.query.end = dateRange[1]

      this.getVehicleServiceData()
    },
    methods: {
      handlePrint() {
        window.print()
      },
      getVehicleServiceData() {
        getVehicleServiceDataAPI(this.query).then(res => {
          this.tableData = res.map(item => {
            // 分割派车时间
            if (item.dispatch_time) {
              item.dispatchTime = item.dispatch_time.split(' ')
            }
            // 分割申请时间
            if (item.apply_time) {
              item.applyTime = item.apply_time.split(' ')
            }
            // 分割返回时间
            if (item.return_time) {
              item.returnTime = item.return_time.split(' ')
            }
            return item
          })
        })
      }
    }
  }
</script>

<style scoped>
  @media print {
    .header {
      display: none;
    }
  }

  table {
    width: 1123px;
    margin: 20px auto;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #DCDFE6;
    color: #606266;
    text-align: center;
  }

  th {
    font-size: 14px;
    padding: 8px 5px;
  }
  
  /* 分页时，不截断表格行 */
  tr {
    page-break-inside: avoid;
  }

  td {
    font-size: 12px;
    padding: 5px;
  }

  .main {
    position: relative;
    padding: 20px;
  }

  .content {
    width: 1123px;
    height: auto;
    margin: 20px auto;
  }

  .header {
    text-align: end;
  }
</style>