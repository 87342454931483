// 派车管理
import request from '@/utils/util.js'

/**
 * @description 车辆管理
 */
// 新增、编辑
export function saveVehicleAPI(data) {
	return request.post('/car/save', data)
}

// 列表
export function getVehicleDataAPI(data) {
	return request.get('/car/list', data)
}


/**
 * @description 派车服务 
 */
// 新增、编辑
export function saveVehicleServiceAPI(data) {
	return request.post('/car/application_save', data)
}

// 列表
export function getVehicleServiceDataAPI(data) {
	return request.get('/car/application_list', data)
}

/**
 * @description 司机相关
 */
// 司机列表
export function getDriverDataAPI(data) {
	return request.get('/car/driver_list', data)
}

// 司机出行安排
export function getDriverTripDataAPI(data) {
	return request.get('/car/my_schedule', data)
}


// 派车信息统计
export function getStatisticsAPI(data) {
	return request.get('/car/statistics', data)
}

// 设置派车记录值
export function setVehicleValueAPI(data) {
	return request.post('/car/application_value_set', data)
}

// 取消派车
export function cancelCarAPI(data) {
	return request.post('/car/car_cancel', data)
}

// 申请用车
export function applicationCarAPI(data) {
	return request.post('/car/user/apply', data)
}

// 申请用车列表
export function getApplicationCarListAPI(data) {
	return request.get('/car/user/apply_list', data)
}


// 获取车辆维护列表
export function getCarRecordListAPI(data) {
	return request.get('/car/maintain/record_list', data)
}


// 保存车辆维护列表
export function saveCarRecordListAPI(data) {
	return request.post('/car/maintain/record_save', data)
}